import React from 'react'
import Layout from '../../components/layout'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Seo from '../../utils/seo';


export default function BunFestivalRoute() {
    return (
        <Layout>
            <Container>
                <Row>
                    <Col>
                        <h2 className='about_slogan'>長洲太平清醮2023 飄色巡遊路線</h2>
                        <p>起源於居民扮成神明出巡、驅除瘟神的習俗，現在已演變成小孩裝扮成神明或古今人物，而近年主辦單位亦以香港當年的大事新聞作飄色主題。在飄色巡遊中，小孩化妝後做靜態的人物造型或簡單的動作表演，站在支架上遊行，給人一種「凌空飄然」的視覺效果。</p>
                        <ul><li>飄色巡遊：2023年5月26日（星期五）下午2:00至4:00</li></ul>
                        <p>活動路線：由北社街出發，沿新興街、大興堤路(部份)、中學路、中興街、大菜園路、大新後街、興隆正街、新興後街轉入新興街，最後返回北社街為終點。</p>
                        <iframe className='pt-3' title="飄色巡遊路線" src="https://www.google.com/maps/d/embed?mid=1gneqMsZwaBBljpJaVgFTbtMIXL8VcTs&ehbc=2E312F" width="100%" height="600"></iframe>
                        <hr></hr>
                        <section className='pb-3'>
                            <p>以上項目資料僅供參考，如發現資料有誤，歡迎<a className="temple-detail-contact" href="mailto:support@m2gether.com">電郵</a>指正以便我們了解及跟進。</p>
                        </section>
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
}


export const Head = () => (
    <Seo
        title="長洲太平清醮2023 活動時間表 + 飄色巡遊路線 + 交通小貼士"
        description="包山2023/長洲｜說到香港的傳統盛事，不得不提的就是長洲太平清醮了！自2005年復辦搶包山比賽，每逢太平清醮長洲都會出現大量人流，不少遊客也會專程前往感受熱鬧"
        siteUrl="http://m2gether.com/bunfestival"
    />
)




